@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  @apply box-border p-0 m-0;
}

/* fonts */

@import url('https://fonts.googleapis.com/css2?family=Work+Sans:ital,wght@0,100..900;1,100..900&display=swap');

/* body{
  @apply bg-[#FFFBF0]
} */
.get-started-box {
  box-shadow: 0px 8px 8px -4px #00000008;
  box-shadow: 0px 20px 24px -4px #00000014;
  border-radius: 20px;
  z-index: 999;
  transform-style: preserve-3d;
}

.shadow-full {
  box-shadow: 0px 4px 32px 0px #95300017;
}

.get-started-box::after {
  content: '';
  position: absolute;
  right: -8px;
  top: calc(50% - 10px);
  background-color: white;
  width: 20px;
  height: 20px;
  border-radius: 5px;
  box-shadow: 0px 8px 8px -4px #00000008;
  box-shadow: 0px 20px 24px -4px #00000014;
  transform: rotate(45deg);
}

/* for the Review slide only current slide active and else should be blur */
.slick-slide {
  transition:
    transform 0.3s ease,
    filter 0.3s ease; /* Add transition for smooth effect */
  filter: blur(0) !important;
  background: transparent;
  /* opacity: 60%; */
}
.slick-slide.slick-active {
  opacity: 100%;
}

#tesimonial-slider .slick-slide {
  transition:
    transform 0.3s ease,
    filter 0.3s ease; /* Add transition for smooth effect */
  filter: blur(0) !important;
  background: transparent;
  opacity: 60%;
}

#tesimonial-slider .slick-slide.slick-active {
  opacity: 100%;
}

#hero-slider .slick-dots {
  bottom: 70px;
}
.faq-research {
  #faq {
    li {
      position: relative;
      overflow: hidden;
      padding: 1em;
      margin: 0;
      margin-bottom: 15px;
      border-radius: 8px;
      background: #fff;
      box-shadow: 0 5px 3px -2px rgba(0, 0, 0, 0.1);
      -webkit-tap-highlight-color: transparent;
      input[type=checkbox] {
        position: absolute;
        cursor: pointer;
        width: 100%;
        height: 100%;
        z-index: 1;
        opacity: 0;
        touch-action: manipulation;
        top: 0;
        left: 0;
        &:checked {
          & + h2 + p {
            display: block;
          }
          & + h2 {
            i:before {
              transform: translate(-2px, 0) rotate(45deg);
            }
            i:after {
              transform: translate(2px, 0) rotate(-45deg);
            }
          }
        }
      }
      i {
        position: absolute;
        transform: translate(-6px, 0);
        margin-top: 28px;
        right: 15px;
        top: 20px;
        @media (max-width: 767px) {
          top: 15px;
        }
        &:before,
        &:after {
          content: "";
          position: absolute;
          width: 3px;
          height: 9px;
          color: #6F7283;
          transition: all 0.3s;
        }
        &:before {
            transform: translate(2px, 0) rotate(45deg);
            background: #31364E;
        }
        &:after {
          transform: translate(-2px, 0) rotate(-45deg);
          background: #31364E;
        }
      }
    }
    h2 {
      font-size: 28px;
      line-height: 36x;
      text-align: left;
      padding: 15px 30px 0;
      font-weight: 500;
      margin: 0;
      @media (max-width: 767px) {
        font-size: 20px;
        line-height: 26px;
        padding-left: 10px;
      }
    }
    p {
      color: #6F7283;
      text-align: left;
      font-family: "Work Sans";
      font-size: 24px;
      line-height: 33px;
      position: relative;
      z-index: 2;
      padding: 6px 50px 0 31px;
      display: none;
      @media (max-width: 767px) {
        font-size: 16px;
        line-height: 22px;
        padding-left: 10px;
      }
    }
  }
}
@media (max-width: 360px) {
  .responsive-width {
    width: 100%;
  }
}

@media (min-width: 361px) and (max-width: 768px) {
  .responsive-width {
    width: 100%;
  }
}

@media (min-width: 769px) {
  .responsive-width {
    width: 25%;
  }
}
/* Styling to override the homepage heroslider's first slide - start */
@media (min-width: 701px) {
  .slick-slide .world-meditates {
    & > div {
      left: 295px;
    }
    button {
      font-size: 18px;
      margin-top: 115px;
      width: 175px;
    }
  }
  .slick-slide .stepping-into-silence {
    & > div {
      right: 60px;
      width: 444px;
      & > *:nth-child(2) {
        font-size: 28px;
      }
    }
    .font-work-sans {
      font-weight: 400;
      .font-normal {
        font-weight: 700;
      }
    }
    button {
      font-size: 24px;
    }
  }
}
@media (min-width: 768px) {
  .research-banner {
    background: url(/assets/images/banner-research-sudarshan-kirya.jpg) no-repeat 100% center;
    background-size: cover;
  }
  .ancient-breath {
    background: url(/assets/images/ancient-breath-vector.png) no-repeat bottom center;
    background-size: 1092px 379px;
  }
  .ready-to-find {
    background-image: url(/assets/images/left-thread.png), url(/assets/images/right-thread.png);
    background-repeat: no-repeat, no-repeat;
    background-position: 37px 50px, calc(100% - 37px) 50px;
  }
}
@media (max-width: 767px) {
  .research-banner {
    background: url(/assets/images/research-banner-bg-mobile.png) no-repeat left bottom;
    background-size: cover;
  }
}
@media (max-width: 700px) {
  /*.slick-slide .world-meditates {
    & > div {
      width: 100%;
      background: url(/assets/images/world-meditate-mobile-banner-bottom.webp) no-repeat bottom center;
      background-size: contain;
      background-position: 50% 34px;
    }
    button {
      background: rgba(255, 146, 0, 0.7);
        position: absolute;
        width: calc(100% - 50px);
        height: 50px;
        left: 50%;
        transform: translateX(-50%);
        top: -10px;
    }
  }*/
  .slick-slide .stepping-into-silence {
    .font-work-sans {
      font-weight: 400;
      .font-normal {
        font-weight: 700;
      }
    }
  }
}
/* Styling to override the homepage heroslider's first slide - end */

.slick-dots li {
  margin: 0px -1px !important;
}

.slick-dots li button:before {
  font-family: 'slick';
  margin-top: 20px !important;
  font-size: 10px !important;
  line-height: 20px;
}

.flip-card {
  background-color: transparent;
  perspective: 1000px;
}

.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  transition: transform 0.6s;
  transform-style: preserve-3d;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
}

.flip-card-hidden {
  transform: rotateY(0deg);
}

.flip-card-front,
.flip-card-back {
  position: absolute;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.flip-card-back {
  transform: rotateY(180deg);
}
