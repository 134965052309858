html,
body {
  scroll-behavior: smooth;
}

body {
  font-family:
    Work Sans,
    sans-serif;
  color: $text-color;
  position: relative;
}

b,
strong {
  font-weight: 600;
}

aside {
  width: 100%;
  padding: 11px 0;
  text-align: center;
  background-image: linear-gradient(to left, $blue-hover, $blue);
  font-size: 15px;
  font-weight: 400;
  line-height: normal;
  color: #fff;
  white-space: nowrap;

  img {
    margin-right: 15px;
  }

  ~ header .menu .menu-item .sub-menu {
    top: 115px !important;
  }
}

.section-title {
  font-family: Lora;
  font-size: 36px;
  font-weight: bold;
  color: $text-color;

  &_thin {
    font-weight: 400 !important;
  }
}

a,
.link {
  cursor: pointer;
  border: none;
  outline: none;
  background: none;
  color: $blue;
  // text-decoration: underline;
  transition: all 0.3s ease-in-out;
  padding: 0;

  &:focus {
    outline: none;
  }

  &:hover {
    color: $blue-hover;
  }

  &:active {
    color: $blue-active;
  }
}

.link_dark {
  color: $text-color;

  &:hover {
    color: $grey;
  }

  &:active {
    color: $dark;
  }
}

.link_orange {
  color: $orange;

  &:hover {
    color: $yellow;
  }

  &:active {
    color: $peach;
  }
}

.link_gray {
  color: $light-grey-2;

  &:hover {
    color: $light-grey;
  }

  &:active {
    color: $grey;
  }
}

label {
  margin: 0;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

input,
button {
  outline: none;
}

p {
  margin-bottom: 0;
}

.mt-6 {
  margin-top: 3.5rem !important;
}

.discount {
  color: #fa4141;
  text-decoration: line-through;
}

.dropdown-toggle {
  display: flex;
  align-items: center;
  color: #31364e;

  &:after {
    position: static !important;
    margin-top: 7px;
    transition: all 0.3s ease-in-out;
  }
}

.input-block.error {
  .input-tooltip {
    color: #fa4141;
    opacity: 1;
  }

  input {
    border-color: #fa4141 !important;
  }
}

.input-tooltip {
  position: absolute;
  pointer-events: none;
  top: 72px;
  opacity: 0;
  border: $grey-border;
  background-color: #fff;
  padding: 9px 15px;
  border-radius: 4px;
  font-size: 12px;
  line-height: 1.33;
  color: #303650;
  transition: all 0.3s ease-in-out;
  z-index: 999;

  &.active {
    pointer-events: unset;
    opacity: 1;
  }

  .tooltip-arrow {
    position: absolute;
    top: -4px;
  }

  .tooltip-arrow::after {
    content: '';
    display: block;
    border-top: $grey-border;
    border-left: $grey-border;
    background-color: white;
    width: 7px;
    height: 7px;
    transform: rotate(45deg);
  }
}

.btn-primary {
  cursor: pointer;
  border: none;
  padding: 16px 20px;
  font-size: 16px;
  font-weight: 600;
  color: white;
  border-radius: $border-radius-btn;
  background-color: $blue;
  background-image: $button-primary;
  transition: all 0.3s ease-in-out;
  text-decoration: none;

  &:hover {
    background-image: $button-primary-hover;
    text-decoration: none;
  }

  &:active {
    background-image: $button-primary-active;
    color: rgba(255, 255, 255, 0.8) !important;
  }

  &:hover,
  &:focus,
  &:active {
    box-shadow: none !important;
    border: none !important;
    box-shadow: none !important;
    outline: none !important;
  }
}

.btn-secondary {
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  text-decoration: none;
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  border: none;
  line-height: normal;
  height: 50px;
  border-radius: $border-radius-btn;
  padding: 15px 20px;
  background: rgb(255, 134, 91);
  background-image: $button-secondary;
  transition: all 0.3s ease-in-out;

  &:hover,
  &:focus,
  &:active {
    color: white;
    box-shadow: none !important;
    text-decoration: none !important;
    outline: none !important;
  }

  &:hover {
    background-image: $button-secondary-hover;
  }

  &:active {
    background-image: $button-secondary-active;
    color: rgba(255, 255, 255, 0.8) !important;
  }
}

.btn-outline {
  cursor: pointer;
  padding: 15px 20px;
  outline: none !important;
  font-size: 16px;
  font-weight: 600;
  line-height: normal;
  text-align: center;
  height: 50px;
  color: $text-color;
  background-color: transparent;
  border-radius: $border-radius-btn;
  border: solid 1px $text-color;
  transition: all 0.3s ease-in-out;

  &:hover {
    color: $light-grey;
    border-color: $light-grey;
  }

  &:active {
    color: #10111a;
    border-color: #10111a;
  }

  &.white {
    border-color: white;
    color: white;

    &:hover {
      background-color: rgba(216, 216, 216, 0.2);
    }

    &:active {
      border-color: rgba(255, 255, 255, 0.8);
      color: rgba(255, 255, 255, 0.8);
      background-color: rgba(0, 0, 0, 0.2);
    }
  }
}

.custom-checkbox {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  z-index: 99;
  width: 20px;
  height: 20px;
  transition: all 0.3s ease-in-out;

  & + label {
    position: relative;

    padding: 0;
    width: 20px;
    height: 20px;
    transition: all 0.3s ease-in-out;
  }

  // Box.
  & + label:before {
    content: '';
    margin-right: 10px;
    display: inline-block;
    vertical-align: text-top;
    width: 20px;
    height: 20px;
    border-radius: 4px;
    background: white;
    border: $grey-border;
    transition: all 0.3s ease-in-out;
  }

  // Box hover
  &:hover + label:before {
    border-color: $blue-hover;
  }

  // Box checked
  &:checked + label:before {
    border-color: $blue;
    background: $blue;
  }

  // Box checked
  &:checked:hover + label:before {
    border-color: $blue-hover;
    background: $blue-hover;
  }

  // Disabled state label.
  &:disabled + label {
    color: #b8b8b8;
    cursor: auto;
  }

  // Disabled box.
  &:disabled + label:before {
    border-color: $border-color !important;
    background: #f7f5f4 !important;
  }

  // Disabled box.
  &:checked:disabled + label::after {
    content: url('/img/ic-tick-disabled.svg');
  }

  // Checkmark. Could be replaced with an image
  &:checked + label:after {
    content: url('/img/ic-tick.svg');
    position: absolute;
    left: 50%;
    top: 58%;
    width: 20px;
    height: 20px;
    transform: translate(-50%, -50%);
  }
}

.custom-radio {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  z-index: 99;
  width: 20px;
  height: 20px;
  transition: all 0.3s ease-in-out;

  & + label {
    position: relative;
    padding: 0 0 0 30px;
    transition: all 0.3s ease-in-out;
    font-size: 14px;
    cursor: pointer;

    span {
      font-weight: bold;
    }
  }

  // Circle.
  & + label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: white;
    border: $grey-border;
    transition: all 0.3s ease-in-out;
  }

  // Circle hover
  &:hover + label:before {
    border-color: $blue-hover;
  }

  // Circle checked
  &:checked + label:before {
    border-color: $blue;
  }

  // Circle checked
  &:checked:hover + label:before {
    border-color: $blue-hover;
  }

  // Disabled state label.
  &:disabled + label {
    color: #b8b8b8;
    cursor: auto;
  }

  // Disabled circle.
  &:disabled + label:before {
    border-color: $border-color !important;
    background: #f7f5f4 !important;
  }

  // Radio mark. Could be replaced with an image
  &:checked + label:after {
    content: '';
    position: absolute;
    left: 5px;
    top: 5px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: $blue;
  }
}

.select-box {
  position: relative;
  display: block;
  width: 100%;
  white-space: nowrap;
  color: #31364e;

  &.no-valid {
    .select-box__current {
      border-color: #fa4141;

      &::after {
        border-top-color: #fa4141;
      }
    }
  }

  &_rounded {
    .select-box {
      &__placeholder {
        border-radius: 8px;
        padding: 8px 15px;
      }

      &__current {
        height: 50px;
        width: 100%;
        border-radius: 8px;
        border: solid 1px #e9e9e9;

        &:after {
          right: 20px;
        }

        &:focus {
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0;
        }
      }

      &__input-text {
        border-radius: 8px;
        padding: 8px 40px 8px 15px;
      }

      &__list {
        margin-top: 0;
        border-top: none;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
      }
    }
  }

  &__placeholder {
    position: absolute;
    z-index: 0;
    color: #9598a6;
    padding: 1px 2px;
  }

  &__current {
    height: 35px;
    cursor: pointer;
    outline: none;
    width: 100%;
    border-bottom: 1px solid #e9e9e9;
    background-color: transparent;
    font-size: 16px;
    line-height: 2;
    transition: all 0.25s ease-in-out;
    padding: 0;
    text-align: left;

    &:hover:not(:focus) {
      border-color: #89beec;

      &::after {
        border-top-color: #89beec;
      }
    }

    &::after {
      content: '';
      position: absolute;
      z-index: 2;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
      width: 0;
      height: 0;
      border-left: 4px solid transparent;
      border-right: 4px solid transparent;
      border-top: 4px solid #c4c5cc;
      transition: all 0.25s ease-in-out;
    }

    &:focus {
      border-color: #2465b3;

      &::after {
        transform: translateY(-50%) rotate(180deg);
        border-top-color: #2465b3;
      }

      & + .select-box__list {
        opacity: 1;
        visibility: visible;
      }
    }
  }

  &__value {
    width: min-content;
  }

  &__input {
    display: none;

    &:checked + .select-box__input-text {
      display: flex;
    }

    &-text {
      display: none;
      position: absolute;
      justify-content: space-between;
      z-index: 1;
      width: 100%;
      width: calc(100% - 2px);
      margin: 0;
      background-color: white;
      padding: 1px 20px 1px 2px;

      .price {
        font-weight: bold;
      }
    }
  }

  &__list {
    position: absolute;
    z-index: 1;
    min-width: min-content;
    padding: 8px 0;
    list-style: none;
    opacity: 0;
    visibility: hidden;
    transition: 0.25s;
    background-color: white;
    margin-top: 5px;
    width: 100%;
    border: 1px solid #2465b3;
    border-radius: 8px;

    li {
      width: 100%;
    }
  }

  &__option {
    display: flex;
    justify-content: space-between;
    align-items: center;
    white-space: nowrap;
    width: 100%;
    padding: 12px 15px;
    cursor: pointer;
    font-size: 14px;
    transition: background-color 0.25s ease-in-out;

    .price {
      font-weight: bold;
    }

    img {
      display: none;
    }

    &:hover,
    &:focus {
      background-color: rgba(137, 190, 236, 0.15);
    }
  }
}

.course-name {
  font-family: Lora, serif;
  font-weight: bold;
  font-size: 24px;
  line-height: 1.29;
  margin-bottom: 15px;
}

.course-popup {
  position: fixed;
  bottom: 0;
  width: 100vw;
  background-color: white;
  border-top: $grey-border;
  z-index: 25;

  .course-card {
    display: flex;
    align-items: center;
    padding: 15px 20px;

    &__info {
      display: flex;
      width: 100%;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-end;

      .price-list {
        .price {
          margin-bottom: 5px;
        }
      }

      .price {
        display: inline-block;
        font-size: 12px;
        font-weight: 600;
        margin-bottom: 0;
      }

      .course-card__course-name {
        font-size: 16px;
        font-weight: bold;
        margin-top: 5px;
        margin-bottom: 5px;
        line-height: 1.13;
        color: $text-color;
      }

      .course-card__date {
        font-size: 12px;
        line-height: 1.33;
        color: $light-grey;
      }

      .link {
        font-size: 12px;
        padding: 0;
      }
    }
  }
}

.mobile-modal {
  background: #fff;
  width: 100%;
  display: none;
  height: 0;
  margin: 0;
  padding: 0;
  transition: all 600ms cubic-bezier(0.86, 0, 0.07, 1);
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0;
  overflow: auto;
  text-align: left;
  z-index: 999;
  transition: all 0.5s ease-in-out;

  &.show {
    display: block;
    opacity: 1;
    height: 100vh;
    position: fixed;
  }

  &__header {
    padding: 40px 20px 20px 20px;
    color: white;
    background-image: url('/img/image.png');
    background-position-y: 100%;
    background-size: cover;
    position: relative;
    overflow: hidden;

    &_silent {
      background-image: url('/img/silent-retreat-checkout-bg@2x.png');
      background-position-y: 50%;
    }

    button {
      margin-top: 15px;
    }

    .new-price {
      font-size: 18px;
      font-weight: 500;
      margin-bottom: 5px;
    }

    .common-price {
      font-size: 14px;
      margin-bottom: 0;
    }
  }

  &__body {
    overflow-y: auto;
    padding: 20px;

    .course-detail {
      display: flex;
      flex-direction: column;
      font-size: 14px;
      font-weight: 600;
      line-height: 1.57;
      color: $text-color;

      &:not(:first-child) {
        margin-top: 10px;
      }

      span {
        font-weight: 400;
      }
    }

    .course-more {
      margin-top: 20px;
      font-size: 12px;
      line-height: 1.33;
      color: $light-grey;

      &__full {
        display: none;

        &.show {
          display: block;
        }
      }
    }
  }

  .close-modal {
    cursor: pointer;
    pointer-events: all;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 32px;
    height: 32px;
    background-color: white;
    box-shadow: 0 2px 25px 0 rgba(61, 139, 232, 0.2);
    border-radius: 50%;
    margin-bottom: 190px;

    .close-line {
      display: block;
      height: 2px;
      width: 12px;
      border-radius: 1px;
      background-color: $grey;
      transition: all 0.3s ease-in-out;

      &:first-child {
        transform: rotate(45deg);
      }

      &:last-child {
        margin-top: -2px;
        transform: rotate(-45deg);
      }
    }

    &:hover {
      .close-line {
        background-color: $blue;
      }
    }

    &:active {
      .close-line {
        background-color: $blue-active;
      }
    }
  }

  &__header_v2 {
    background-image: none;
    padding-bottom: 10px;

    .close-modal {
      margin-bottom: 40px;
    }

    .course-name {
      font-size: 24px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.33;
      letter-spacing: normal;
      color: $text-color;
      margin-bottom: 30px;
    }

    .new-price {
      font-size: 16px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: $text-color;
      margin-bottom: 0;
    }
  }

  &__body_v2 {
    .course-list-title {
      font-size: 16px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.38;
      letter-spacing: normal;
      color: $text-color;
      margin-bottom: 0;
    }

    .course-list-info {
      margin: 10px 0 20px 0;
      list-style-type: disc;
      padding-left: 20px;

      &__item {
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.38;
        letter-spacing: normal;
        color: $orange;

        span {
          color: $light-grey;
        }

        strong {
          color: $text-color;
        }
      }
    }

    .course-more {
      margin: 0;
    }

    .agreement {
      &__group {
        display: flex;
      }

      &__group_important {
        .custom-checkbox + label:before {
          border-color: #ee8585;
        }

        .custom-checkbox:checked + label:before {
          border-color: $blue-hover;
          background-color: white;
        }

        .custom-checkbox:checked + label:after {
          content: url('/img/ic-tick-blue.svg');
        }
      }

      &__text {
        margin-left: 10px;
        margin-top: 4px;
        font-size: 12px;
        line-height: 1.33;
        color: $text-color;

        span {
          display: block;

          &:not(:first-child) {
            margin-top: 10px;
          }
        }
      }

      &__important {
        display: flex;
        align-items: center;
        opacity: 1;
        pointer-events: all;
        margin-top: 15px;
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #ee8585;
        transition: all 0.3s ease-in-out;

        &.hide {
          opacity: 0;
          pointer-events: none;
        }

        &-icon {
          width: 20px;
          height: 20px;
          margin-right: 10px;
        }
      }
    }
  }
}

.modal-window {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  width: 100%;
  height: 100vh;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(24, 26, 38, 0.5);
  z-index: 99999;
  transition: all 0.3s ease-in-out;

  &__card {
    position: relative;
    background-color: white;
    display: none;
    flex-direction: column;
    text-align: center;
    max-width: 400px;
    border-radius: 8px;
    box-shadow: 0 2px 35px 0 rgba(0, 0, 0, 0.05);

    &.show {
      display: flex;
    }
  }

  &__header {
    padding: 40px 30px 25px 30px;
  }

  &__body {
    border-top: $grey-border;
    padding: 25px 30px 30px 30px;
  }

  &__title {
    font-family: Lora, serif;
    font-size: 26px;
    line-height: 1.46;
    font-weight: bold;
    color: #303650;
    margin-bottom: 11px;
  }

  &__subtitle {
    font-size: 18px;
    font-weight: 400;
    line-height: 1.44;
    color: #303650;
  }

  &__text {
    text-align: left;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.25;
    color: $light-grey;

    span {
      display: block;

      &:not(:first-child) {
        margin-top: 10px;
      }
    }
  }

  &__btn {
    width: 100%;
  }

  &__close {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: absolute;
    outline: none;
    top: 0;
    right: -50px;
    border: none;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background-color: white;

    .close-line {
      display: block;
      height: 2px;
      width: 12px;
      border-radius: 1px;
      background-color: $grey;
      transition: all 0.3s ease-in-out;

      &:first-child {
        transform: rotate(45deg);
      }

      &:last-child {
        margin-top: -2px;
        transform: rotate(-45deg);
      }
    }

    &:hover {
      .close-line {
        background-color: $blue;
      }
    }

    &:active {
      background-color: rgba(255, 255, 255, 0.8);

      .close-line {
        background-color: $blue-active;
      }
    }

    &:active,
    &:hover,
    &:focus {
      outline: none;
    }
  }

  .course-join-card {
    position: relative;
    width: 620px;
    width: 620px;
    border-radius: 8px;
    box-shadow: 0 2px 35px 0 rgba(0, 0, 0, 0.05);
    background-color: #fff;
    display: none;
    pointer-events: none;

    &.show {
      display: block;
      pointer-events: all;
    }

    &__body {
      padding: 40px 25px 30px;
      border-radius: 8px 8px 0 0;
      background-color: #fff;
    }

    &__title {
      font-size: 26px;
      line-height: 1.46;
      text-align: center;
      margin-bottom: 30px;
    }

    &__subtitle {
      font-size: 16px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.38;
      letter-spacing: normal;
      color: $text-color;
    }

    &__text-container {
      margin-bottom: 25px;
    }

    &__text {
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.38;
      letter-spacing: normal;
      color: $text-color;

      &:not(:first-child) {
        margin-top: 10px;
      }
    }

    &__excludes {
      margin-top: 15px;
      font-size: 12px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: normal;
      color: $light-grey;
    }

    &__list {
      list-style: disc;
      padding-left: 20px;
      margin: 10px 0 0 0;

      &-item {
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.38;
        letter-spacing: normal;
        color: $orange;

        span {
          color: $light-grey;
        }
      }
    }

    &__footer {
      padding: 25px 25px 30px;
      text-align: right;
      background-color: $orange-opacity;
    }

    &.digital-member-join_journey {
      .course-join-card__title {
        margin-bottom: 25px;
      }

      .course-join-card__list {
        span {
          color: $text-color;
        }

        &-item {
          &-tooltip {
            position: relative;
            cursor: pointer;
            border-bottom: 1px $text-color dashed;
            transition: all 0.3s ease-in-out;

            &-block {
              pointer-events: none;
              position: absolute;
              opacity: 0;
              padding: 10px 15px;
              width: 230px;
              top: -50px;
              left: 50%;
              box-shadow: $blue-shadow;
              border: $grey-border;
              background-color: #ffffff;
              font-size: 12px;
              font-weight: normal;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.33;
              letter-spacing: normal;
              text-align: center;
              color: $text-color;
              transform: translateX(-50%);
              transition: all 0.3s ease-in-out;

              &::after {
                content: '';
                position: absolute;
                top: 95%;
                left: 50%;
                width: 8px;
                height: 8px;
                transform: rotate(45deg);
                background-color: white;
                border-bottom: $grey-border;
                border-right: $grey-border;
              }
            }

            &:hover {
              color: $grey;
              border-color: $grey;

              .course-join-card__list-item-tooltip-block {
                opacity: 1;
              }
            }
          }
        }
      }

      .course-join-card__footer {
        text-align: center;
        padding: 30px 25px;

        &-price {
          font-size: 20px;
          font-weight: 600;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.5;
          letter-spacing: normal;
          text-align: center;
          color: $text-color;
          margin-bottom: 20px;
        }
      }
    }

    .close-modal {
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      position: absolute;
      outline: none;
      top: 0;
      right: -50px;
      border: none;
      width: 32px;
      height: 32px;
      border-radius: 50%;
      background-color: white;

      .close-line {
        display: block;
        height: 2px;
        width: 12px;
        border-radius: 1px;
        background-color: $grey;
        transition: all 0.3s ease-in-out;

        &:first-child {
          transform: rotate(45deg);
        }

        &:last-child {
          margin-top: -2px;
          transform: rotate(-45deg);
        }
      }

      &:hover {
        .close-line {
          background-color: $blue;
        }
      }

      &:active {
        background-color: rgba(255, 255, 255, 0.8);

        .close-line {
          background-color: $blue-active;
        }
      }

      &:active,
      &:hover,
      &:focus {
        outline: none;
      }
    }
  }
}

.course-bottom-card {
  border-top: $grey-border;
  position: fixed;
  opacity: 0;
  pointer-events: none;
  bottom: 0;
  z-index: 99;
  width: 100vw;
  background-color: white;
  padding: 15px 0;
  display: flex;
  align-items: center;
  transition: all 0.3s ease-in-out;

  &.show {
    opacity: 1;
    pointer-events: all;
  }

  &__container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__info-block {
    display: flex;
    align-items: center;
  }

  &__img {
    width: 60px;
    height: 60px;
    margin-right: 20px;

    img {
      width: 100%;
      height: 100%;
    }
  }

  &__info {
    p {
      font-size: 16px;
      color: #20242c;
    }

    div {
      display: flex;
      align-items: center;

      .course-bottom-card__info-course-name {
        font-size: 20px;
        font-weight: 600;
        color: #20242c;
        margin-bottom: 0;
      }

      ul {
        list-style-type: disc;
        padding-left: 30px;

        li {
          font-size: 20px;
          font-weight: 400;
          color: $light-grey-2;

          b {
            font-weight: 600;
            color: #20242c;
          }
        }
      }
    }
  }
}

.auth {
  display: none;

  &.active {
    display: flex;
  }

  .icon-wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;

    .icon {
      cursor: pointer;
      border-radius: 4px;
      border: $grey-border;
      background-color: #fff;
      transition: all 0.3s ease-in-out;

      &:hover {
        border-color: $blue-hover;
      }

      &:active {
        border-color: #4191e9;
      }

      img {
        width: 50px;
        height: 50px;
      }

      &:not(:last-child) {
        margin-right: 20px;
      }
    }
  }

  .modal-window__header {
    padding: 0;

    .modal-window__subtitle {
      margin: 35px 42px 30px 42px;
    }

    .auth-btn-wrapper {
      display: flex;
    }

    .auth-btn {
      padding: 0 0 11px 0;
      width: 50%;
      font-size: 18px;
      font-weight: 600;
      text-align: center;
      color: $light-grey-2;
      background-color: white;
      border: none;
      border-bottom: 2px solid $border-color;
      transition: all 0.3s ease-in-out;
      outline: none;

      &.active {
        color: $blue;
        border-color: $blue;
      }
    }
  }

  .modal-window__body {
    border: none;
  }

  form {
    margin-top: 20px;
    display: none;
    opacity: 0;
    flex-direction: column;
    transition: all 0.3s ease-in-out;

    &.active {
      display: flex;
    }

    &.show {
      opacity: 1;
    }

    input {
      padding: 12px 15px;
      border-radius: 4px;
      border: $grey-border;
      margin-bottom: 15px;
      font-size: 16px;
      color: $light-grey;
      transition: all 0.3s ease-in-out;

      &.validate {
        border-color: $red-error;
      }

      &:hover {
        border-color: $blue-hover;
      }

      &:focus {
        border-color: $blue-active;
      }
    }

    .validation-input {
      text-align: left;
      font-size: 12px;
      line-height: 1.33;
      color: $red-error;
      margin-bottom: 15px;
    }

    .link {
      text-align: left;
    }

    .checkbox-wrapper {
      display: flex;
      margin-top: 17px;
    }

    .checkbox-text {
      font-size: 12px;
      text-align: left;
      margin-left: 10px;
    }
  }
}

.sidebar-button {
  display: block;
  padding: 0;
  width: 24px;
  height: 24px;
  background: none;
  border: none;

  &__icon {
    display: block;
  }

  &:focus {
    outline: none;
  }
}

body._lock {
  overflow: hidden;
}

.app-button {
  display: block;
  position: relative;
  cursor: pointer;
  border: none;
  outline: none;
  border-radius: 8px;
  width: 170px;
  height: 50px;
  background-color: black;
  text-decoration: none;

  &__image {
    object-fit: contain;
    width: 100%;
    height: 100%;
  }
}
